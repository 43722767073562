.device-mockup {
    opacity: 0;
    -webkit-animation: heroMockup 0.6s ease forwards 0.6s;
    animation: heroMockup 0.6s ease forwards 0.6s;
}

@-webkit-keyframes heroMockup {
    from {
        -webkit-transform: translateY(80px);
        transform: translateY(80px);
        opacity: 0;
    }
    to {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes heroMockup {
    from {
        -webkit-transform: translateY(80px);
        transform: translateY(80px);
        opacity: 0;
    }
    to {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}
